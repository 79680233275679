import React, { useEffect, useContext, useState } from "react";
import "./dashboard-content.component.scoped.scss";
// import { DashboardService } from "../../services/dashboard/dashboard.service";
import DashboardFirstBlock from "../dashboard-first-block/dashboard-first-block.component";
import DashboardSecondBlock from "../dashboard-second-block/dashboard-second-block.component";
import DashboardThirdBlock from "../dashboard-third-block/dashboard-third-block.component";
import { GlobalContext } from "../../../../store";
import { Box } from "@material-ui/core";
import PagesHeadComponent from "../../../../components/pages-head-component/pages-head.component";
import { icons } from "../../../../statics/icons";
import { useTranslation } from "react-i18next";
import { ContentLayout } from "../../../../layouts/content/content.layout";
import { DashboardService } from "../../../../services/dashboard/dashboard.service";
import { MapperService } from "../../../../services/mapper/mapper.service";
import moment from "moment";
import DashboardFifthBlockComponent from "../dashboard-fifth-block/dashboard-fifth-block.component";
import DashboardFourthBlock from "../dashboard-fourth-block/dashboard-fourth-block.component";

export default function DashboardContent() {
  const handleCardsChange = (orderedCards: any[]) => {};
  const [t] = useTranslation();
  const dashboardService = new DashboardService();
  const mapperService = new MapperService();
  const { helpData, setHelpContent } = useContext(GlobalContext);
  const [counts, setCounts] = useState<any>([]);
  const [highRiskCases, setHighRiskCases] = useState<any>({});
  const [latestCases, setLatestCases] = useState<any>({});
  const [openTasks, setOpenTasks] = useState<any>({});
  const [categoriesChart, setCategoriesChart] = useState();
  const [statusChart, setStatusChart] = useState();

  const fetchData = async () => {
    const dashboardData = await dashboardService.getDashboardData();
    const mappedCounts = mapperService.mapCounts(dashboardData.counts);
    const mappedLatestCases = mapperService.mapTableCards(
      dashboardData.newestCases,
      "LATEST_CASES"
    );
    const mappedHighRiskCases = mapperService.mapTableCards(
      dashboardData.highRiskCases,
      "HIGH_RISK_CASES"
    );
    const openTodoList = mapperService.mapTableCards(
      dashboardData.openTodoList,
      "OPEN_TASKS"
    );
    setOpenTasks(openTodoList);
    setCounts(mappedCounts);
    setHighRiskCases(mappedHighRiskCases);
    setLatestCases(mappedLatestCases);
  };

  const fetchCharts = async () => {
    const durationDate = {
      from: moment().subtract(12, "months").format("YYYY-MM-DD"),
      to: moment().add(1, "days").format("YYYY-MM-DD"),
    };
    const categoriesChartData = await dashboardService.getCategoriesChartData(
      durationDate
    );
    setCategoriesChart(categoriesChartData);

    const statusChartData = await dashboardService.getStatusChartData(
      durationDate
    );
    setStatusChart(statusChartData);
  };

  useEffect(() => {
    fetchData();
    fetchCharts();
    setHelpContent(helpData.Dashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <PagesHeadComponent
        title={t("PAGES_HEAD.AI_RISK_LANDSCAPE_TITLE")}
        subTitle={t("PAGES_HEAD.AI_RISK_LANDSCAPE_SUB_TITLE")}
        logo={icons.PAGES.HEAD.AI_RISK_LANDSCAPE}
      />
      <ContentLayout>
        <Box className="dashboard">
          <Box className="theme-container dashboard-content">
            <DashboardFirstBlock counts={counts} />
            <DashboardSecondBlock
              categoriesChart={categoriesChart}
              highRiskCases={highRiskCases}
            />
            <DashboardThirdBlock
              statusChart={statusChart}
              latestCases={latestCases}
            />
            <DashboardFourthBlock openTasks={openTasks} />
            <DashboardFifthBlockComponent />
          </Box>
        </Box>
      </ContentLayout>
    </Box>
  );
}
