import React from "react";
import "./chart-bar-horizontal.component.scoped.scss";
import { Box, Tooltip, Typography, withStyles } from "@material-ui/core";

const StyledTooltip = withStyles((theme) => ({
  tooltip: (props: any) => ({
    backgroundColor: props.bg || "var(--primary)",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 13,
  }),
}))(Tooltip);

const ChartBarHorizontalComponent = (props: any) => {
  const { data } = props;

  return (
    <Box className="horizontal__bar">
      <Box className="items">
        {data.map((item: any, index: number) => {
          return (
            <StyledTooltip
              title={item.label}
              key={index}
              placement="left-end"
              bg={item.color}
            >
              <Box
                className="item"
                style={{ background: item.color, width: item.percentage + "%" }}
              >
                <Typography variant="body1">{item.total}</Typography>
                <Typography variant="body1" className="trim">
                  {item.label}
                </Typography>
              </Box>
            </StyledTooltip>
          );
        })}
      </Box>
    </Box>
  );
};
export default ChartBarHorizontalComponent;
