import React, { useState, useEffect } from "react";
import "./dashboard-second-block.component.scoped.scss";
import { useTranslation } from "react-i18next";
import DashboardGraphCard from "../dashboard-graph-card/dashboard-graph-card.component";
import { Box } from "@material-ui/core";
import DashboardTableCard from "../dashboard-table-card/dashboard-table-card.component";

export default function DashboardSecondBlock(props: any) {
  const [t] = useTranslation();
  const { categoriesChart, highRiskCases } = props;

  return (
    <Box className="cards__content mb-5">
      {categoriesChart && (
        <Box className="card__content">
          <DashboardGraphCard
            title={t("DASHBOARD_CARDS." + categoriesChart.title)}
            subTitle={t(categoriesChart.subTitle)}
            chart={categoriesChart.chart}
            type={categoriesChart.type}
          />
        </Box>
      )}
      {highRiskCases.data && (
        <Box className="card__content">
          <DashboardTableCard
            title={t(highRiskCases.title)}
            subTitle={t(highRiskCases.subTitle)}
            columns={highRiskCases.columns}
            tableName={highRiskCases.tableName}
            data={highRiskCases.data}
          />
        </Box>
      )}
    </Box>
  );
}
