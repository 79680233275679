import { icons } from "../../../statics/icons";

export interface SideMenuItem {
  tab: number;
  name: string;
  iconActive?: string;
  iconCompleted?: string;
  permission: string[];
}

export const sideMenuItems: SideMenuItem[] = [
  {
    tab: 1,
    name: "USECASE_CREATE.AI_PRODUCT",
    iconActive: icons.checkLight,
    iconCompleted: icons.playLight,
    permission: ["PRODUCTS", "INVENTORY", "USER"],
  },
  {
    tab: 2,
    name: "USECASE_CREATE.AI_SCENARIO",
    iconActive: icons.checkLight,
    iconCompleted: icons.playLight,
    permission: ["PRODUCTS", "INVENTORY", "USER"],
  },
  {
    tab: 3,
    name: "USECASE_CREATE.AI_RISK_ASSISMENT",
    iconActive: icons.checkLight,
    iconCompleted: icons.playLight,
    permission: ["PRODUCTS", "INVENTORY", "USER"],
  },
  {
    tab: 4,
    name: "USECASE_CREATE.AI_RISK_LEVEL",
    iconActive: icons.checkLight,
    iconCompleted: icons.playLight,
    permission: ["PRODUCTS", "INVENTORY", "USER"],
  },
  {
    tab: 5,
    name: "USECASE_CREATE.APPLICATION_AREAS",
    iconActive: icons.checkLight,
    iconCompleted: icons.playLight,
    permission: ["PRODUCTS", "INVENTORY", "USER"],
  },
  {
    tab: 6,
    name: "USECASE_CREATE.AI_CLASSIFICATION",
    iconActive: icons.checkLight,
    iconCompleted: icons.playLight,
    permission: ["PRODUCTS", "INVENTORY"],
  },
  {
    tab: 7,
    name: "USECASE_CREATE.AI_TEAM",
    iconActive: icons.checkLight,
    iconCompleted: icons.playLight,
    permission: ["PRODUCTS", "INVENTORY"],
  },
];
