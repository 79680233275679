export const chartBarColor = "rgb(83, 168, 226)";
export const chartTextColor = "rgb(176, 176, 176)";
export const chartDoughnutColors = [
  // old Color
  // "rgb(83, 168, 226)",
  // "rgb(238, 106, 34)",
  // "rgb(242, 175, 29)",
  // "rgb(175, 180, 154)",
  // "rgb(202, 49, 11)",
  // "rgb(163, 54, 159)",
  // "rgb(34, 154, 131)",
  // "rgb(221, 94, 72)",
  // "rgb(107, 189, 68)",
  // "rgb(56, 123, 198)",
  // "rgb(238, 136, 187)",
  // "rgb(94, 176, 217)",
  // "rgb(205, 152, 36)",
  // // PAIPER-ONE PALETTE COLOR
  // "rgb(161 141 186)",
  // "rgb(180, 176, 199)",
  // "rgb(142, 137, 165)",
  // "rgb(158, 151, 182)",
  // "rgb(125, 118, 157)",
  // "rgb(171, 165, 196)",
  // "rgb(109, 102, 143)",
  // "rgb(153, 143, 175)",
  // "rgb(100, 92, 135)",
  // "rgb(181, 178, 204)",
  // "rgb(130, 122, 161)",
  // "rgb(120, 112, 164)",
  // "rgb(101, 92, 132)",
  // "rgb(154, 150, 181)",
  // "rgb(86, 76, 114)",
  // "rgb(144, 134, 172)",
  // "rgb(78, 69, 104)",
  // "rgb(169, 159, 193)",
  // RISK Level new color
  // "#F0B631",
  // "#C33939",
  // "#507850",
];
