import { TableColumn } from "../../types/table-column";

export const highRiskCasesColumns: TableColumn[] = [
  {
    type: "case",
    fieldName: "PROJECTS",
    value: "title",
    secondaryValue: "internalId",
    width: "22%",
  },
  {
    type: "object",
    fieldName: "RISK_LEVEL",
    value: "category",
    width: "22%",
  },
  {
    type: "progress",
    fieldName: "PROGRESS",
    value: "progress",
    width: "18%",
  },
  {
    type: "date",
    fieldName: "LAST_CHANGE",
    value: "updated_at",
    width: "15%",
  },
];
