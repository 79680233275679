import "./files-table-row.component.scoped.scss";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "moment/locale/de";
import { Skeleton } from "@material-ui/lab";
import { icons } from "../../../../../../../statics/icons";
import { formatDate, turncate } from "../../../../../../../helpers";
import { CaseContext } from "../../../../../../../store";
import { TimelineDeleteCommentComponent } from "../../../../../../../components/timeline-delete-comment/timeline-delete-comment.component";
import { Box } from "@material-ui/core";

const FilesTableRow = (props: any) => {
  const [t] = useTranslation();
  const { user } = props;
  const {
    caseDetails,
    completedStatusId,
    setSelectedFile,
    selectedFile,
  } = useContext(CaseContext);
  const [userImageError, setUserImageError] = useState(false);

  const downloadFile = () => {
    const a = document.createElement("a");
    a.href = props.url;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const selectFile = () => {
    const file = { ...props };
    setSelectedFile(file);
  };

  return (
    <div className="file-wrapper">
      <div
        className={`table-row ${
          selectedFile && selectedFile.id === props.id ? "selected-file" : ""
        }`}
        onClick={selectFile}
      >
        <div className="file-section">
          <div className="file-type-icon">
            {props.loading ? (
              <Skeleton
                variant="rect"
                height={30}
                width={30}
                style={{ marginRight: "5px" }}
              />
            ) : (
              <img src={icons[props.type]} alt="" />
            )}
          </div>
          <div className="file-name d-flex flex-column justify-content-center align-items-start">
            {props.loading ? (
              <Skeleton variant="text" height={15} width={100} />
            ) : (
              <div className="filename-text">{turncate(props.file, 25)}</div>
            )}
            {props.loading ? (
              <Skeleton variant="text" height={15} width={60} />
            ) : (
              <div className="file-size">{props.size}</div>
            )}
          </div>
        </div>
        <div className="user-section">
          <div className="timeline-user align-items-center">
            {props.loading ? (
              <Skeleton variant="circle" height={35} width={35} />
            ) : (
              <Box className="user__profile__pic">
                {user.profilePicURL && !userImageError ? (
                  <img
                    src={user.profilePicURL}
                    title={user.firstName + " " + user.lastName}
                    alt=""
                    onError={setUserImageError.bind(null, true)}
                  />
                ) : (
                  <span className="text-uppercase">
                    {user.firstName ? user.firstName[0] + user.lastName[0] : ""}
                  </span>
                )}
              </Box>
            )}
            <div className="user-info pl-3">
              {props.loading ? (
                <Skeleton variant="text" height={15} width={80} />
              ) : (
                <div className="user-name">
                  {user.firstName ? user.firstName + " " + user.lastName : ""}
                </div>
              )}
              {props.loading ? (
                <Skeleton variant="text" height={15} width={150} />
              ) : (
                <div className="comment-date">
                  {formatDate(props.date, "dddd, DD.MM.YYYY")} {t("at")}{" "}
                  {formatDate(props.date, "HH:mm")}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="action-section">
          <div className="icons">
            {props.loading ? (
              <div className="icons-wrap">
                <Skeleton
                  variant="circle"
                  height={30}
                  width={30}
                  className="mr-2"
                />
                <Skeleton variant="circle" height={30} width={30} />
              </div>
            ) : (
              <div className="icons-wrap">
                <div className="download-icon" onClick={downloadFile}>
                  <img src={icons.downloadLight} alt="" />
                </div>

                {props.isAuthorizedUser &&
                  caseDetails.statusId !== completedStatusId && (
                    <div className="delete-icon">
                      <TimelineDeleteCommentComponent
                        fileId={props.id}
                        tab="files"
                      />
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilesTableRow;
