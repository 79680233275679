import React from "react";
import { DashboardGraphCardProps } from "../../../../types/dashboard-graph-card-props";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import "./dashboard-graph-card.component.scoped.scss";
import { ChartComponent } from "../chart/chart.component";
import { ChartDoughnutComponent } from "../chart-doughnut/chart-doughnut.component";
import { useTranslation } from "react-i18next";
import { chartDoughnutColors } from "../../../../statics/charts";
import DashboardGraphFilter from "../dashboard-graph-filter/dashboard-graph-filter.component";
import { Box, Typography } from "@material-ui/core";
import ChartBarHorizontalComponent from "../chart-bar-horizontal/chart-bar-horizontal.component";

export default function DashboardGraphCard(props: DashboardGraphCardProps) {
  const [t] = useTranslation();
  const { title, subTitle, chart, type } = props;

  function isZero(item: number) {
    return item === 0;
  }

  return (
    <Box className="dashboard-card  global__box__content">
      <Box className="card-details">
        <Typography variant="subtitle1" className="card-title">
          {title}
        </Typography>
        <Typography variant="body1" className="card-sub-title">
          {subTitle}
        </Typography>
      </Box>

      <Box className="card-graph">
        {type === "horizontalBar" &&
          (!chart.every(isZero) ? (
            <ChartBarHorizontalComponent data={chart} />
          ) : (
            <Box className="falback-wrapper">
              <img
                src="dashboard-fallback.png"
                className="empty-chart-fallback"
                alt="empty-charts"
              />
            </Box>
          ))}
        {type === "bar" &&
          (!chart.data.every(isZero) ? (
            <Box className="chart">
              <ChartComponent
                data={chart.data}
                labels={chart.labels}
                name={title}
                tooltipLabel={t(title)}
              />
            </Box>
          ) : (
            <Box className="falback-wrapper">
              <img
                src="dashboard-fallback.png"
                className="empty-chart-fallback"
                alt="empty-charts"
              />
            </Box>
          ))}
        {type === "pie" &&
          (!chart.data.every(isZero) ? (
            <Box className="chart">
              <ChartDoughnutComponent
                data={chart.data}
                labels={chart.labels}
                colors={chart.colors}
              />
              <Box
                className={`chart-stats ${
                  chart.labels.length > 6 ? "split-column" : ""
                }`}
              >
                {chart.labels.map((item: any, index: any) => {
                  return (
                    <Box className="stats-row" key={index}>
                      <Box
                        className="stats-color"
                        style={{ backgroundColor: chart.colors[index] }}
                      />
                      <Box className="stats-label trim">
                        {chart.data[index]}% {item}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : (
            <Box className="falback-wrapper">
              <img
                src="dashboard-fallback.png"
                className="empty-chart-fallback"
                alt="empty-charts"
              />
            </Box>
          ))}
      </Box>
    </Box>
  );
}
