import React, { useContext, useEffect } from "react";
import HeaderComponent from "../../../components/header/header.component";
import { CaseProvider, GlobalContext } from "../../../store";
import { CommonService } from "../../../services/common/common.service";
import { useTranslation } from "react-i18next";
import { CaseContentComponent } from "../../../modules/case/components/details/case-content/case-content.component";

const CasePage = (props: any) => {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  const caseId = props.match.params.id;
  const { setPageTitle } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      commonService.setTitle(t("PAGE_META_TITLES.USE_CASE_MANAGER"));
      setPageTitle(t("PAGE_META_TITLES.USE_CASE_MANAGER"));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CaseProvider>
      <HeaderComponent />
      <CaseContentComponent caseId={caseId} />
    </CaseProvider>
  );
};

export default CasePage;
