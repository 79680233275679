import React from "react";
import "./coming-soon.component.scoped.scss";
import { useTranslation } from "react-i18next";

const ComingSoonComponent = () => {
  const [t] = useTranslation();
  return (
    <div className="wrapper">
      <img src="fallback.png" className="img" alt="coming soon" />
      <h3 className="title">{t("coming soon")}...</h3>
    </div>
  );
};
export default ComingSoonComponent;
