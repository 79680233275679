import React, { useContext, useEffect } from "react";
import HeaderComponent from "../../../components/header/header.component";
import { CasesProvider, GlobalContext } from "../../../store";
import { CommonService } from "../../../services/common/common.service";
import { useTranslation } from "react-i18next";
import AIDemandContentComponent from "../../../modules/case/components/list/ai-demand-content/ai-demand-content.component";

const AIDemandPage = () => {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  const { setPageTitle } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      commonService.setTitle(t("PAGE_META_TITLES.AI_DEMAND"));
      setPageTitle(t("PAGE_META_TITLES.AI_DEMAND"));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CasesProvider>
      <HeaderComponent />
      <AIDemandContentComponent />
    </CasesProvider>
  );
};

export default AIDemandPage;
