import React, { useContext, useEffect } from "react";
import "./todo.page.scoped.scss";
import { CommonService } from "../../services/common/common.service";
import { useTranslation } from "react-i18next";
import HeaderComponent from "../../components/header/header.component";
import { TodoListProvider } from "../../store/providers/todos.provider";
import TodoContent from "../../modules/todo/components/todo-content/todo-content.component";
import { GlobalContext } from "../../store";

export default function TodoPage() {
  const commonService = new CommonService();
  const [t] = useTranslation();
  const { setPageTitle } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      commonService.setTitle(t("PAGE_META_TITLES.AI_TASKS"));
      setPageTitle(t("PAGE_META_TITLES.AI_TASKS"));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TodoListProvider>
      <HeaderComponent />
      <TodoContent />
    </TodoListProvider>
  );
}
