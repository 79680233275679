import React, { useEffect } from "react";
import "./AI-risk-assessment.component.scoped.scss";
import { Box } from "@material-ui/core";
import TabContentHeadComponent from "../tab-content-head/tab-content-head.component";
import { useTranslation } from "react-i18next";

const AIRiskAssessment = () => {
  const [t] = useTranslation();

  useEffect(() => {
    // Dynamically load the Typeform script after the component mounts
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box className="content">
      <TabContentHeadComponent
        title={t("USECASE_CREATE.AI_RISK_ASSESSMENT_TITLE")}
        subTitle={t("USECASE_CREATE.AI_RISK_ASSESSMENT_SUB_TITLE")}
      />
      <div className="mt-5" data-tf-live="01J8KW7S6K9E3GWRY56S841Y2C"></div>
    </Box>
  );
};
export default AIRiskAssessment;
