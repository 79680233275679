import { useEffect } from "react";

export default function useOutsideAlerter(ref: any, handler: any) {
  useEffect(() => {
    // Function for click event
    const listener = (event: any) => {
      if (Array.isArray(ref)) {
        if (
          !ref[0].current ||
          !ref[1].current ||
          ref[0].current.contains(event.target) ||
          ref[1].current.contains(event.target)
        ) {
          return;
        }
      } else {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
      }
      handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
