import React from "react";
import "./sign-up.page.scoped.scss";
import { AuthProvider } from "../../store";
import { SignUpContentComponent } from "../../modules/auth/components/sign-up-content/sign-up-content.component";
import { AuthLayout } from "../../layouts/auth/auth.layout";

const SignUpPage = (props: any) => {
  const token = props.match.params.id;
  const tenantId = props.match.params.tenantId;
  return (
    <AuthLayout>
      <AuthProvider>
        <SignUpContentComponent token={token} tenantId={tenantId} />
      </AuthProvider>
    </AuthLayout>
  );
};

export default SignUpPage;
