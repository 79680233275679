import "./add-new-user.scoped.scss";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import { emailRegex } from "../../statics/validators";
import { UserService } from "../../services/user/user.service";
import { showToastMsg } from "../../helpers";

const AddNewUserModal = (props: any) => {
  const { user } = props;
  const userService: UserService = new UserService();

  const [firstName, setFirstName] = useState(user ? user.firstName : "");
  const [lastName, setLastName] = useState(user ? user.lastName : "");
  const [email, setEmail] = useState(user ? user.email : "");
  const [role, setRole] = useState(user ? user.role : "");
  const [defaultPasswordChecked, setDefaultPasswordChecked] = useState(false);
  const [group, setGroup] = useState(user ? user.group : "");
  const [emailError, setEmailError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [focused, setFocused] = useState(false);
  const [t] = useTranslation();

  async function addUser() {
    const data: any = {
      firstName: capitalize(firstName),
      lastName: capitalize(lastName),
      email: email,
      role: role,
      group: group,
      defPassChecked: defaultPasswordChecked,
    };
    const res = user
      ? await userService.updateUser(user.id, { role: data.role })
      : await userService.addUser({ users: [data] });
    if (res && res.users.length && res.users[0].message === "SUCCESS") {
      showToastMsg("success", t("User added successfully"));
    } else if (res && res.users.length && res.users[0].message === "EXISTING") {
      showToastMsg("error", t("User exists already"));
    } else {
      showToastMsg("error", t("System error, please try again later"));
    }
  }

  const changeRole = (value: any) => {
    setRole(value);
    if (value === "superUser") {
      setDefaultPasswordChecked(false);
      setGroup("internal");
    }
  };

  const handleClose = (value: any) => {
    props.onClose(value);
    // reset state
    setDefaultPasswordChecked(false);
    setGroup("");
    setEmailError(false);
    setEmail("");
    setLastName("");
    setFirstName("");
    setRole("");
    setFocused(false);
  };

  const handleUpdate = async () => {
    if (saving) return;
    setSaving(true);
    await addUser();
    setSaving(false);
    handleClose(true);
  };

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFocus = (input: any) => {
    if (input && !focused) {
      setTimeout(() => {
        input.focus();
        setFocused(true);
      }, 1);
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={handleClose.bind(null, false)}
      centered
      dialogClassName="modal-new-user"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("invite new user")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col">
            <TextField
              fullWidth
              label={t("firstName")}
              onChange={(e: any) => setFirstName(e.target.value.trim())}
              value={firstName}
              name="first name"
              variant={"filled"}
              inputRef={handleFocus}
              disabled={!!user}
              inputProps={{
                maxLength: 30,
              }}
            />
          </div>
          <div className="col margin-second-field">
            <TextField
              fullWidth
              label={t("lastName")}
              onChange={(e: any) => setLastName(e.target.value.trim())}
              value={lastName}
              name="last name"
              variant={"filled"}
              disabled={!!user}
              inputProps={{
                maxLength: 30,
              }}
            />
          </div>
        </div>
        <div className="mt-3">
          <TextField
            fullWidth
            label="Email"
            onChange={(e: any) => {
              setEmail(e.target.value);
              e.target.value
                ? setEmailError(!emailRegex.test(e.target.value))
                : setEmailError(false);
            }}
            value={email}
            name="email"
            variant={"filled"}
            type="email"
            disabled={!!user}
            error={emailError ? true : false}
          />
          {emailError && <div className="error-msg ">{t("invalid-email")}</div>}
        </div>
        <div className="row mt-3">
          <div className="col">
            <FormControl className="select-input select-grey w-100">
              <InputLabel id="role">{t("role")}</InputLabel>
              <Select
                labelId="role"
                id="damage-amount-select"
                name="role"
                value={role}
                onChange={(e: any) => {
                  changeRole(e.target.value);
                }}
                IconComponent={ExpandMoreIcon}
                disabled={user && user.type === "superUser"}
              >
                <MenuItem
                  value={"superUser"}
                  disabled={user && user.type !== "superUser"}
                >
                  {t("coordinator")}
                </MenuItem>
                <MenuItem value={"editor"}>{t("EDITOR")}</MenuItem>
                <MenuItem value={"auditor"}>{t("AUDITOR")}</MenuItem>
                <MenuItem value={"contributor"}>{t("USER")}</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="col margin-second-field">
            <FormControl
              className={
                "select-input w-100 " +
                (role !== "superUser" ? "select-grey" : "")
              }
            >
              <InputLabel
                id="internal/external"
                className={role === "superUser" ? "disableLabel" : ""}
              >
                {t("internal/external")}
              </InputLabel>
              <Select
                labelId="internal/external"
                id="damage-amount-select"
                name="internal/external"
                value={group}
                onChange={(e: any) => setGroup(e.target.value)}
                IconComponent={ExpandMoreIcon}
                disabled={role === "superUser" || !!user}
              >
                <MenuItem value={"internal"}>{t("internal")}</MenuItem>
                <MenuItem value={"external"}>{t("external")}</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        {!user && (
          <FormControlLabel
            className="checkbox-def-password mt-15px checkbox-global"
            control={
              <Checkbox
                checked={defaultPasswordChecked}
                onChange={() =>
                  setDefaultPasswordChecked(!defaultPasswordChecked)
                }
                name="jaChecked"
                size="small"
                disabled={role === "superUser"}
              />
            }
            label={t("set default password")}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <>
          <Button
            variant="outlined"
            color="primary"
            className="btn-secondary-theme"
            onClick={handleClose.bind(null, false)}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="btn-primary-theme"
            onClick={handleUpdate}
            disabled={
              emailError ||
              firstName.length === 0 ||
              lastName.length === 0 ||
              group.length === 0 ||
              email.length === 0 ||
              role.length === 0 ||
              (user && user.role === role)
            }
          >
            {saving ? (
              <CircularProgress color="inherit" />
            ) : (
              <>
                <CheckIcon /> {!!user ? t("EDIT_ROLE") : t("add user")}
              </>
            )}
          </Button>
        </>
      </Modal.Footer>
    </Modal>
  );
};

export default AddNewUserModal;
