import moment from "moment";
import "moment/locale/de";
import { Case } from "../../types/case";
import { Status } from "../../types/status";
import { formatDate } from "../../helpers";
import { statusIcons } from "../../statics/status-icons";
import { Question } from "../../types/question";
import { CaseWorkflow } from "../../types/case-workflow";
import { CountCards } from "../../statics/countCards";
import { TableCards } from "../../statics/tableCards";
import { LocalStorageService } from "../local-storage/local-storage";

export class MapperService {
  private localStorageService: LocalStorageService = new LocalStorageService();
  private user = this.localStorageService.getData("user");

  public mapCases = (res: any) => {
    const tenantIcon: string = this.localStorageService.getData("tenantIcon");
    let caseIcon = "/icons/logos/logo-bauhaus.svg";
    caseIcon = tenantIcon || '"/fav/moweex.png"';
    const readyCases = res.data.map((item: any) => {
      item.isDueDate = moment(item.dueDate).isBefore(moment());
      item.img = caseIcon;
      item.checked = false;
      return item;
    });
    const paginationObj = {
      items: res.per_page,
      currentPage: res.page,
      totalPages: res.totalPages,
      total: res.total,
    };
    return { readyCases, paginationObj };
  };

  public mapTenants = (tenants: any) => {
    const mappedTenants = Array.isArray(tenants) ? [...tenants] : [];
    mappedTenants.forEach((item: any) => {
      if (item.logoUrl) {
        item.iconSrc = item.logoUrl;
      } else {
        item.iconSrc = "/fav/moweex.png";
      }
    });
    const filteredTenants = mappedTenants.filter(
      (item) => item.isDeleted === 0 && item.status === "verified"
    );
    return filteredTenants;
  };

  public mapUsers = (res: any) => {
    const readyUsers = res.users ? res.users.data || [] : [];
    const counts = res.counts || {};
    counts.total = counts.coordinator + counts.external + counts.internal;
    const paginationObj = {
      items: res?.users?.per_page,
      currentPage: res?.users?.page,
      totalPages: res?.users?.totalPages,
      total: res?.users?.total,
    };
    return { counts: res.counts, readyUsers, paginationObj };
  };

  public mapCounts = (cardsInfo: any) => {
    const counts = [...CountCards];
    counts.forEach((count: any) => {
      count.total = cardsInfo[count.key];
    });
    return counts;
  };

  public mapTableCards = (data = [], tableName: string) => {
    const tableCards = [...TableCards];
    const tableCard = tableCards.find(
      (card: any) => card.tableName === tableName
    );
    if (tableCard) {
      tableCard.data = data;
      return tableCard;
    }
  };

  public mapStatuses = (statuses: Status[]) => {
    return statuses.map((status: Status, index: number) => {
      status = { ...new Status(), ...status };
      status.icon = statusIcons.white;
      status.iconOrange = statusIcons.orange;
      status.iconGrey = statusIcons.grey;
      return status;
    });
  };

  public mapCase = (res: Case, statuses: Status[]) => {
    const mappedCase: Case = { ...res };
    const mappedtimelineStatuses: Status[] = [...statuses];
    let isStatusAfterActiveStatus = false;
    for (let index = 0; index < statuses.length; index++) {
      const status = mappedtimelineStatuses[index];
      status.expand = false;
      status.dimmed = false;
      if (mappedCase.statusKey === status.key) {
        mappedCase.statusColor = status.color;
        mappedCase.statusId = status.id;
        status.expand = true;
        isStatusAfterActiveStatus = true;
        continue;
      }
      if (isStatusAfterActiveStatus) {
        status.dimmed = true;
      }
    }
    // check optional fields
    mappedCase.optionalFieldsEmpty = true;
    if (
      mappedCase.involvedPersons ||
      mappedCase.amount ||
      mappedCase.involvedDepartment ||
      (mappedCase.startDate && mappedCase.endDate) ||
      mappedCase.involvedCompany ||
      mappedCase.relationship ||
      mappedCase.startDate ||
      mappedCase.endDate
    ) {
      mappedCase.optionalFieldsEmpty = false;
    }
    if (mappedCase.startDate && mappedCase.endDate) {
      mappedCase.duration = `${formatDate(
        mappedCase.startDate,
        "dddd, DD.MM.YYYY"
      )} - ${formatDate(mappedCase.endDate, "dddd, DD.MM.YYYY")}`;
    }
    if (mappedCase.AI) {
      mappedCase.AI = this.handleAIJson(mappedCase.AI);
    }
    return { mappedCase, mappedtimelineStatuses };
  };

  public handleAIJson = (res: any) => {
    let formattedJson: any = {};
    formattedJson.start_date = res.start_date ? res.start_date : null;
    formattedJson.end_date = res.end_date ? res.end_date : null;

    formattedJson.amount =
      res.loss_amount_max && res.loss_amount_min
        ? `${Number(res.loss_amount_max).toLocaleString("en-US")} € - ${Number(
            res.loss_amount_min
          ).toLocaleString("en-US")} €`
        : "";
    formattedJson.affected_company = res.affected_company
      ? res.affected_company
      : null;
    formattedJson.relationship = res.relationship ? res.relationship : null;

    formattedJson.departments =
      res.departments && res.departments.length !== 0
        ? res.departments.map((item: any) => {
            return {
              name: item.text,
              percentage: `${Math.floor(item.probability * 100)}%`,
            };
          })
        : null;

    formattedJson.persons =
      res.persons && res.persons.length !== 0
        ? res.persons.map((item: any) => {
            return {
              name: item.text,
              percentage: `${Math.floor(item.probability * 100)}%`,
            };
          })
        : null;

    formattedJson.companies =
      res.organizations && res.organizations.length !== 0
        ? res.organizations.map((item: any) => {
            return {
              name: item.text,
              percentage: `${Math.floor(item.probability * 100)}%`,
            };
          })
        : null;

    formattedJson.locations =
      res.locations && res.locations.length !== 0
        ? res.locations.map((item: any) => {
            return {
              name: item.text,
              percentage: `${Math.floor(item.probability * 100)}%`,
            };
          })
        : null;

    formattedJson.moneys =
      res.moneys && res.moneys.length !== 0
        ? res.moneys.map((item: any) => {
            return {
              name: item.text,
              percentage: `${Math.floor(item.probability * 100)}%`,
            };
          })
        : null;

    formattedJson.categories =
      res.case_types && res.case_types.length !== 0
        ? res.case_types.map((item: any) => {
            return {
              name: item.case_type,
              percentage: `${Math.floor(item.score * 100)}%`,
            };
          })
        : null;

    formattedJson.description = res.description ? res.description : null;
    formattedJson.entities = res.entities ? res.entities : null;

    return formattedJson;
  };

  public mapTimelineStatus = (caseDetails: Case, statuses: Status[]) => {
    const mappedCase: Case = { ...caseDetails };
    const mappedtimelineStatuses: Status[] = [...statuses];
    let isStatusAfterActiveStatus = false;
    for (let index = 0; index < statuses.length; index++) {
      const status = mappedtimelineStatuses[index];
      status.expand = false;
      status.dimmed = false;
      if (mappedCase.statusKey === status.key) {
        status.expand = true;
        isStatusAfterActiveStatus = true;
        continue;
      }
      if (isStatusAfterActiveStatus) {
        status.dimmed = true;
      }
    }
    return mappedtimelineStatuses;
  };

  mapAmounts = (amounts: any) => {
    const mappedAmounts = [...amounts];
    mappedAmounts.unshift({ id: null, value: null });
    return mappedAmounts;
  };

  mapRelations = (relations: any) => {
    const mappedRelations = [...relations];
    mappedRelations.unshift({ id: null, name: null });
    return mappedRelations;
  };

  mapNotifications = (notifications: any) => {
    const mappedNotifications = [...notifications.data];
    mappedNotifications.forEach((item: any) => {
      if (item.type === "caseCreation") {
        item.iconSrc = "/icons/case-status/received-dark-grey.svg";
        item.tab = 0;
      } else if (item.type === "caseAssignment") {
        item.iconSrc = "/icons/invite.svg";
        item.tab = 0;
      } else if (item.type === "newReporterComment") {
        item.iconSrc = "/icons/comment.svg";
        item.tab = 1;
      } else if (item.type === "caseClosure") {
        item.iconSrc = "/icons/case-status/completed-black.svg";
        item.tab = 3;
      } else if (item.type === "statusUpdate") {
        item.iconSrc = "/icons/clock.svg";
        item.tab = 3;
      }
    });
    return mappedNotifications;
  };

  mapCaseQuestions = (caseQuestions: CaseWorkflow[]) => {
    let totalQuestions = 0;
    let totalAnsweredQuestions = 0;
    const formattedCaseQuestions: any = [];
    caseQuestions.map((caseQuestion: any) => {
      formattedCaseQuestions.push(caseQuestion);
      totalQuestions += caseQuestion.questions
        ? caseQuestion.questions.length
        : 0;
      caseQuestion.questions.map((question: Question) => {
        if (question.answer != null) {
          totalAnsweredQuestions += 1;
        }
        return question;
      });
      return caseQuestion;
    });
    return { totalQuestions, totalAnsweredQuestions, formattedCaseQuestions };
  };

  public mapLogs = (res: any) => {
    const readyLogs = res.data.map((item: any) => {
      return item;
    });
    const paginationObj = {
      items: res.per_page,
      currentPage: res.page,
      totalPages: res.totalPages,
      total: res.total,
    };
    return { readyLogs, paginationObj };
  };

  public mapTodoList = (res: any) => {
    const readyTodos = res.todoList.data.map((item: any) => {
      return item;
    });
    const counts = res.counts;
    const paginationObj = {
      items: res.todoList.per_page,
      currentPage: res.todoList.page,
      totalPages: res.todoList.totalPages,
      total: res.todoList.total,
    };
    return { readyTodos, paginationObj, counts };
  };
}
