import React from "react";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../services/common/common.service";
import ForgotPasswordContentComponent from "../../modules/auth/components/forgot-password-content/forgot-password-content.component";
import { AuthLayout } from "../../layouts/auth/auth.layout";

const commonService: CommonService = new CommonService();

const ForgotPasswordPage = () => {
  const [t] = useTranslation();
  commonService.setTitle(t("Case Management"));

  return (
    <AuthLayout>
      <ForgotPasswordContentComponent />
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
