import { appName } from "../../statics/main";

export class CommonService {
  public appName = appName;

  public setTitle = (title: string) => {
    document.title = `${this.appName} ${title ? "|" : ""} ${title}`;
  };

  public getTitle = () => {
    const pageTitle = document.title.replace(`${this.appName} | `, "");
    return pageTitle;
  };
}
