import React, { useContext, useEffect } from "react";
import "./cases-content.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { DataTableComponent } from "../../../../../components/table/data-table/data-table.component";
import { CasesContext, GlobalContext } from "../../../../../store";
import useFetchCases from "../../../../../store/hooks/useFetchCases";
import { PAGE_ROLES } from "../../../types/AI-roles";

interface CasesListProps {
  role: PAGE_ROLES;
}

const CasesContent: React.FC<CasesListProps> = ({ role }) => {
  const [t] = useTranslation();
  const { helpData, setHelpContent } = useContext(GlobalContext);
  const {
    AIInventoryColumns,
    AIProductsColumns,
    AIDemandColumns,
    filter,
    setFilter,
    options,
    pagination,
    sort,
  } = useContext(CasesContext);
  const { loading, data } = useFetchCases(role, filter);

  const paginateTable = (pagination: any) => {
    const { items, currentPage } = pagination;
    const params = {
      ...filter,
      items,
      page: currentPage,
      order: options.sortDirection,
      sort: options.sortColumn,
    };
    setFilter(params);
  };

  const sortTable = (fieldName: string, sortDirection: string) => {
    const params = { fieldName, sortDirection };
    const filterObj = {
      ...filter,
      page: 1,
      items: pagination.items,
      sort: fieldName,
      order: sortDirection,
    };
    sort(params);
    setFilter(filterObj);
  };

  const filterChange = (params: any) => {
    sort({
      fieldName: "",
      sortDirection: "",
    });
    const filterObj = {
      ...params,
      items: pagination.items,
      page: 1,
    };
    setFilter(filterObj);
  };

  const exportData = async () => {
    for (let row of data) {
      if (row.checked) {
        // await downloadPDF(row);
      }
    }
  };

  useEffect(() => {
    setHelpContent(helpData.FilterCases);
    // eslint-disable-next-line
  }, [helpData]);

  return (
    <div className="cases-content">
      <div className="theme-container table-section">
        <DataTableComponent
          tableName="cases"
          loading={loading}
          columns={
            role === "PRODUCTS"
              ? AIProductsColumns
              : role === "INVENTORY"
              ? AIInventoryColumns
              : AIDemandColumns
          }
          caseRole={role}
          data={data}
          options={options}
          sort={sortTable}
          pagination={pagination}
          exportData={true}
          onExport={exportData}
          paginate={paginateTable}
          onFilterChange={filterChange}
          filter={filter}
          title={
            role === "INVENTORY"
              ? t("AI_PROJECTS")
              : t("AI_APPLICATION_SCENARIO")
          }
          subTitle={`${pagination.total} ${t("results")}`}
          clickableRow={["case", "id"]}
        />
      </div>
    </div>
  );
};

export default CasesContent;
