import React from "react";
import "./login.page.scoped.sass";
import { AuthProvider } from "../../store";
import { LoginContentComponent } from "../../modules/auth/components/login-content/login-content.component";
import { AuthLayout } from "../../layouts/auth/auth.layout";

export default function LoginPage() {
  return (
    <AuthLayout>
      <AuthProvider>
        <LoginContentComponent />
      </AuthProvider>
    </AuthLayout>
  );
}
