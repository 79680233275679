import React from "react";
import "./dashboard-third-block.component.scoped.scss";
import { useTranslation } from "react-i18next";
import DashboardTableCard from "../dashboard-table-card/dashboard-table-card.component";
import { Box } from "@material-ui/core";
import DashboardGraphCard from "../dashboard-graph-card/dashboard-graph-card.component";

export default function DashboardThirdBlock(props: any) {
  const { statusChart, latestCases } = props;
  const [t] = useTranslation();

  return (
    <Box className="cards__content mb-5">
      {latestCases.data && (
        <Box className="card__content">
          <DashboardTableCard
            title={t(latestCases.title)}
            subTitle={t(latestCases.subTitle)}
            columns={latestCases.columns}
            tableName={latestCases.tableName}
            data={latestCases.data}
          />
        </Box>
      )}
      {statusChart && (
        <Box className="card__content">
          <DashboardGraphCard
            title={t("DASHBOARD_CARDS." + statusChart.title)}
            subTitle={t(statusChart.subTitle)}
            chart={statusChart.chart}
            type={statusChart.type}
          />
        </Box>
      )}
    </Box>
  );
}
