import React, { useContext } from "react";
import "./side-menu-content.component.scoped.scss";
import { GlobalContext } from "../../store";
import { icons } from "../../statics/icons";
import { history } from "react-router-guard";
import { LocalStorageService } from "../../services/local-storage/local-storage";
import { Box } from "@material-ui/core";
import { SideMenuItemsComponent } from "./components/side-menu-items/side-menu-items.component";

export const SideMenuContentComponent = () => {
  const { fullNav } = useContext(GlobalContext);
  const localStorageService = new LocalStorageService();
  const tenantName = localStorageService.getData("tenantName");

  const handleLogoClick = () => {
    history.replace("/landscape");
  };

  return (
    <Box className={`side__menu__content ${fullNav ? "full" : ""}`}>
      <Box onClick={handleLogoClick} className="side__menu__brand">
        <img src={icons.logo} alt="Paiper" className="brand__logo" />
        {fullNav && <Box className="brand__name">{tenantName}</Box>}
      </Box>
      <SideMenuItemsComponent />
    </Box>
  );
};
