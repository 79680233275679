import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../../services/common/common.service";
import { GlobalContext } from "../../../store";
// import CategoriesContent from "../../../modules/settings/components/roles-management/categories-content/categories-content.component";
import CoordinatorsContent from "../../../modules/settings/components/roles-management/coordinators-content/coordinators-content.component";
import SettingsHead from "../../../modules/settings/shared/settings-head/settings-head.component";
import { ContentLayout } from "../../../layouts/content/content.layout";
import { Box } from "@material-ui/core";

export default function RolesManagementPage(props: any) {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  const { user, setPageTitle } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      commonService.setTitle(t("PAGE_META_TITLES.ROLE_MANAGEMENT"));
      setPageTitle(t("PAGE_META_TITLES.ROLE_MANAGEMENT"));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="role-contain">
      <Box className="content">
        <SettingsHead
          title={t("role management")}
          subTitle={t("organize the permissions of your users here")}
        />
        <ContentLayout>
          {/* {(user.type === "superAdmin" ||
            (user.type === "superUser" && user.role === "coordinator")) && (
            <CategoriesContent />
          )} */}
          {(user.type === "superAdmin" ||
            (user.type === "superUser" && user.role === "coordinator")) && (
            <CoordinatorsContent />
          )}
        </ContentLayout>
      </Box>
    </Box>
  );
}
