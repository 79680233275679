import React, { useContext, useEffect, useState } from "react";
import "./create-case-content.component.scoped.scss";
import { history } from "react-router-guard";
import CloseIcon from "@material-ui/icons/Close";
import { Box, Typography } from "@material-ui/core";
import CreateCaseSidemenuComponent from "../create-case-sidemenu/create-case-sidemenu.component";
import CreateCaseGeneralStepComponent from "../create-case-general-step/create-case-general-step.component";
import AIScenarioStepComponent from "../AI-scenario-step/AI-scenario-step.component";
import ApplicationAreasStepComponent from "../application-areas-step/application-areas-step.component";
import AIClassificationStepComponent from "../AI-classification-step/AI-classification-step.component";
import AITeamStepComponent from "../AI-team-step/AI-team-step.component";
import AIProductStepComponent from "../AI-product-step/AI-product-step.component";
import { SideMenuTabContent } from "../../../types/sidemenu-tab-content";
import CreateCaseActionsComponent from "../create-case-actions/create-case-actions.component";
import { CreateUseCaseContext } from "../../../../../store";
import { LookupsService } from "../../../../../services/lookups/lookups.service";
import { useTranslation } from "react-i18next";
import AIRiskLevel from "../AI-risk-level/AI-risk-level.component";
import AIRiskAssessment from "../AI-risk-assessment/AI-risk-assessment.component";

const CreateCaseContentComponent = () => {
  const [t] = useTranslation();
  const lookupsService = new LookupsService();
  const [activeTab, setActiveTab] = useState(0);
  const [nextButtonStatus, setNextButtonStatus] = useState(false);
  const queryParams = history.location.search;

  const {
    setLands,
    setBranches,
    setAreas,
    setAlgorithms,
    setOperations,
    setTechniques,
    setMachineLearningTypes,
    setCategories,
  } = useContext(CreateUseCaseContext);

  const fetchListData = async () => {
    setLands(await lookupsService.listLands());
    setBranches(await lookupsService.listBranches());
    setAreas(await lookupsService.listAIAreas());
    setOperations(await lookupsService.listOperation());
    setCategories(await lookupsService.listCategories());
    setAlgorithms(await lookupsService.listAIAlgorithms());
    setTechniques(await lookupsService.listAITechniques());
    setMachineLearningTypes(await lookupsService.listMachineLearningTypes());
  };

  useEffect(() => {
    fetchListData();
    // eslint-disable-next-line
  }, []);

  const sideMenuTabContent: SideMenuTabContent[] = [
    {
      tab: 0,
      component: <CreateCaseGeneralStepComponent />,
    },
    {
      tab: 1,
      component: (
        <AIProductStepComponent
          onError={(value: boolean) => setNextButtonStatus(value)}
        />
      ),
    },
    {
      tab: 2,
      component: (
        <AIScenarioStepComponent
          onError={(value: boolean) => setNextButtonStatus(value)}
        />
      ),
    },
    {
      tab: 3,
      component: <AIRiskAssessment />,
    },
    {
      tab: 4,
      component: (
        <AIRiskLevel onError={(value: boolean) => setNextButtonStatus(value)} />
      ),
    },
    {
      tab: 5,
      component: (
        <ApplicationAreasStepComponent
          onError={(value: boolean) => setNextButtonStatus(value)}
        />
      ),
    },
    {
      tab: 6,
      component: (
        <AIClassificationStepComponent
          onError={(value: boolean) => setNextButtonStatus(value)}
        />
      ),
    },
    {
      tab: 7,
      component: (
        <AITeamStepComponent
          onError={(value: boolean) => setNextButtonStatus(value)}
        />
      ),
    },
  ];
  const tabContent = sideMenuTabContent[activeTab].component;
  const tabIndex = sideMenuTabContent[activeTab].tab;

  const close = () => {
    if (queryParams.includes("PRODUCTS")) {
      history.push("/ai-products");
    } else if (queryParams.includes("INVENTORY")) {
      history.push("/ai-inventory");
    } else if (queryParams.includes("USER")) {
      history.push("/ai-demand");
    } else {
      history.push("/landscape");
    }
  };

  return (
    <Box className="content">
      <Box className="w-100 h-100">
        <CreateCaseSidemenuComponent
          activeTab={activeTab}
          queryParams={queryParams}
        />
      </Box>
      <Box className="w-100 h-100 p-4 d-flex flex-column">
        <Box className="mb-4 position-relative">
          <Typography className="text-uppercase" variant="h3" color="primary">
            {t("USECASE_CREATE.CREATE_AI_ASSESSMENT")}
          </Typography>
          <Typography
            variant="subtitle1"
            color="secondary"
            style={{ fontWeight: 400 }}
          >
            {t("USECASE_CREATE.LETS_MAKE_YOUR_AI_PRODUCT_COMPLIANT")}
          </Typography>
          <CloseIcon className="close" onClick={close} />
        </Box>
        {tabContent}
        <Box className="mt-auto">
          <CreateCaseActionsComponent
            buttonStatus={nextButtonStatus}
            activeTab={tabIndex}
            onChangeTab={(tab: number) => setActiveTab(tab)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CreateCaseContentComponent;
