import { Box } from "@material-ui/core";
import React from "react";

export const ContentLayout = (props: any) => {
  return (
    <Box maxWidth="1800px" m="auto" height="100%">
      {props.children}
    </Box>
  );
};
