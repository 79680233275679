import React, { useContext, useEffect, useState } from "react";
import "./AI-risk-level.component.scoped.scss";
import { Box } from "@material-ui/core";
import TabContentHeadComponent from "../tab-content-head/tab-content-head.component";
import { useTranslation } from "react-i18next";
import { CreateUseCaseContext } from "../../../../../store";
import SelectionBox from "../selection-box/selection-box.component";
import { icons } from "../../../../../statics/icons";

const AIRiskLevel = (props: any) => {
  const { onError } = props;
  const [t] = useTranslation();
  const { useCase, setUseCase, categories } = useContext(CreateUseCaseContext);
  const [categoryHover, setCategoryHover] = useState(-1);

  useEffect(() => {
    if (!useCase.categoryId) {
      onError(true);
    } else {
      onError(false);
    }
  }, [useCase, onError]);

  return (
    <Box className="content">
      <TabContentHeadComponent
        title={t("USECASE_CREATE.AI_RIEK_LEVEL_TITLE")}
        subTitle={t("USECASE_CREATE.AI_RIEK_LEVEL_SUB_TITLE")}
      />
      <Box className="mb-4">
        <Box className="items mx-3">
          {categories.map((item: any, index: number) => {
            return (
              <Box
                onMouseOver={() => setCategoryHover(index)}
                onMouseLeave={() => setCategoryHover(-1)}
              >
                <SelectionBox
                  display="vertical"
                  key={item.id}
                  title={item.name}
                  subTitle={item.description}
                  icon={
                    item.iconKey
                      ? icons.PAGES.SETTINGS.ICON_OPTIONS[item.iconKey]
                      : icons.categoryPrimary
                  }
                  activeIcon={
                    item.iconKey
                      ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                          `${item.iconKey}_WHITE`
                        ]
                      : icons.categoryWhite
                  }
                  active={
                    useCase.categoryId === item.id || categoryHover === index
                  }
                  itemKey={item.id}
                  onBoxClick={(id: number | string) =>
                    setUseCase({ ...useCase, categoryId: id as number })
                  }
                />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default AIRiskLevel;
