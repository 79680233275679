import { TableSearchColumn } from "../types/table-column";

export const casesSearchColumns: TableSearchColumn[] = [
  { label: "status", searchId: "statusId" },
  { label: "affected company", searchId: "branchId" },
  { label: "period", searchId: "period" },
  { label: "category", searchId: "categoryId" },
  { label: "clerk", searchId: "assigneeId" },
  { label: "ROLE", searchId: "role" },
];

export const CasesFilterStags = [
  {
    key: 1,
    name: "NEW",
  },
  {
    key: 2,
    name: "IN_PROGRESS",
  },
  {
    key: 3,
    name: "PUBLISHED",
  },
];

export const CasesFilterRiskLevels = [
  {
    key: 1,
    name: "HIGH_RISK",
  },
  {
    key: 2,
    name: "MIDDLE_RISK",
  },
  {
    key: 3,
    name: "NO_RISK",
  },
];
