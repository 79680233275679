import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../../services/common/common.service";
import ProfileContent from "../../../modules/settings/components/profile/profile-content/profile-content.component";
import SettingsHead from "../../../modules/settings/shared/settings-head/settings-head.component";
import { ContentLayout } from "../../../layouts/content/content.layout";
import { Box } from "@material-ui/core";
import { GlobalContext } from "../../../store";

export default function ProfilePage(props: any) {
  const [t] = useTranslation();
  const commonService: CommonService = new CommonService();
  const { setPageTitle } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      commonService.setTitle(t("PAGE_META_TITLES.USER_MANAGEMENT"));
      setPageTitle(t("PAGE_META_TITLES.USER_MANAGEMENT"));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className="role-contain">
      <Box className="content">
        <SettingsHead
          title={t("USER_MANAGEMENT")}
          subTitle={t("organize your account-specific information here")}
        />
        <ContentLayout>
          <ProfileContent />
        </ContentLayout>
      </Box>
    </Box>
  );
}
