import { Box } from "@material-ui/core";
import "./auth.layout.scoped.scss";
import React from "react";

export const AuthLayout = (props: any) => {
  return (
    <Box className="auth-layout">
      <Box className="auth-form">
        <Box>{props.children}</Box>
      </Box>
      <Box className="panel" />
    </Box>
  );
};
