import React, { useEffect, useRef, useState } from "react";
import "./workflow-comments-list.component.scoped.scss";
import { Box, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CasesService } from "../../../../../../../../services/cases/cases.service";
import { formatDate, isValidLength } from "../../../../../../../../helpers";
import { icons } from "../../../../../../../../statics/icons";
import { TimelineDeleteCommentComponent } from "../../../../../../../../components/timeline-delete-comment/timeline-delete-comment.component";
import { TimelineFileComponent } from "../../../../../../../../components/timeline-file/timeline-file.component";

const WorkflowCommentsListComponent = (props: any) => {
  const {
    id,
    user,
    date,
    isAuthorizedUser,
    files,
    questionId,
    caseQuestionId,
    commentIndex,
    deleteComment,
  } = props;
  const [t] = useTranslation();
  const [comment, setComment] = useState(props.comment);
  const [textFieldState, setTextFieldState] = useState(false);
  const [userImageError, setUserImageError] = useState(false);
  const inputRef: any = useRef();
  const maxChar = 500;
  const casesService = new CasesService();

  const handleSaveClick = async () => {
    if (isValidLength(comment, maxChar)) {
      const payload = {
        comment: comment,
      };
      await casesService.updateWorkflowComment(id, payload);
      setTextFieldState(false);
    }
  };

  const handleEditClick = () => {
    setTextFieldState(true);
  };

  useEffect(() => {
    if (comment && textFieldState === true) {
      inputRef.current.focus();
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
      inputRef.current.setSelectionRange(comment.length, comment.length);
    }
  });

  return (
    <Box className="comments-section mx-1">
      <Box className={`timeline-item-comment timeline-comment`}>
        <Box className={`timeline-badge bg-grey-dark`}>
          <Box className="bg-grey-dark" />
        </Box>
        <Box className={`timeline-dash grey-dark`}>
          <hr />
        </Box>
        <Box className={"d-flex justify-content-between align-items-center"}>
          <Box className="timeline-user">
            <Box className="user__profile__pic">
              {user.profilePicURL && !userImageError ? (
                <img
                  src={user.profilePicURL}
                  title={user.firstName + " " + user.lastName}
                  alt=""
                  onError={setUserImageError.bind(null, true)}
                />
              ) : (
                <span className="text-uppercase">
                  {user.firstName ? user.firstName[0] + user.lastName[0] : ""}
                </span>
              )}
            </Box>
            <Box className="user-info pl-3">
              <Box className="user-name">
                {user ? user.firstName + " " + user.lastName : "Anonymous"}
              </Box>
              <Box className="comment-date">
                {formatDate(date, "dddd, DD.MM.YYYY")} {t("at")}{" "}
                {formatDate(date, "HH:mm")}
              </Box>
            </Box>
          </Box>
          <Box className="icons">
            {isAuthorizedUser ? (
              <Box className="icons-wrap">
                {textFieldState ? (
                  <Box className="edit-icon " onClick={handleSaveClick}>
                    <img src={icons.check} alt="" />
                  </Box>
                ) : (
                  <Box className="edit-icon " onClick={handleEditClick}>
                    <img src={icons.edit} alt="" />
                  </Box>
                )}

                {textFieldState ? (
                  <Box
                    className="edit-icon "
                    onClick={() => {
                      setTextFieldState(false);
                      setComment(props.comment);
                    }}
                  >
                    <img src={icons.close} alt="" />
                  </Box>
                ) : (
                  <TimelineDeleteCommentComponent
                    id={id}
                    questionId={questionId}
                    caseQuestionId={caseQuestionId}
                    index={commentIndex}
                    deleteComment={(index: number) => deleteComment(index)}
                    tab="workflow"
                  />
                )}
              </Box>
            ) : null}
          </Box>
        </Box>

        <Box className="comment-content">
          {textFieldState ? (
            <TextField
              id="filled-multiline-flexible"
              multiline
              rows={3}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              variant="filled"
              className={"text-area-multiline w-100"}
              inputRef={inputRef}
            />
          ) : (
            <Box>{comment}</Box>
          )}
        </Box>

        {files && files.length ? (
          <Box className={"row"}>
            {files.map((file: any, index: number) => {
              return (
                <Box className="col-3" key={index}>
                  <TimelineFileComponent
                    name={file.name}
                    type={file.type}
                    size={file.size}
                    url={file.url}
                  />
                </Box>
              );
            })}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default WorkflowCommentsListComponent;
