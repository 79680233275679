import { icons } from "./icons";
export const CountCards = [
  {
    id: "1",
    key: "totalProjects",
    title: "DASHBOARD_CARDS.TOTAL_PROJECTS",
    icon: icons.PAGES.DASHBOARD.PROJECTS,
  },
  {
    id: "2",
    key: "totalTasks",
    title: "DASHBOARD_CARDS.TOTAL_TASKS",
    icon: icons.PAGES.DASHBOARD.TASKS,
  },
  {
    id: "3",
    key: "highRisk",
    title: "DASHBOARD_CARDS.HIGHT_RISK_AI",
    icon: icons.PAGES.DASHBOARD.RISK,
  },
  {
    id: "4",
    key: "limitRisk",
    title: "DASHBOARD_CARDS.LIMITED_MINIMAL_RISK_AI",
    icon: icons.PAGES.DASHBOARD.RISK,
  },
  {
    id: "5",
    key: "lowRisk",
    title: "DASHBOARD_CARDS.GP_AI",
    icon: icons.PAGES.DASHBOARD.RISK,
  },
  {
    id: "6",
    key: "openTasks",
    title: "DASHBOARD_CARDS.OPEN_TASKS",
    icon: icons.PAGES.DASHBOARD.TASKS,
  },
];
