import React from "react";
import "./selection-box.component.scoped.scss";
import { Box, Typography } from "@material-ui/core";

interface SelectionBoxProps {
  title: string;
  subTitle?: string;
  itemKey: string | number;
  active: boolean;
  icon: string;
  activeIcon: string;
  disabled?: boolean;
  display?: "vertical" | "horizontal";
  onBoxClick: (itemKey: string | number) => void;
}
const SelectionBox = (props: SelectionBoxProps) => {
  const {
    title,
    subTitle,
    active,
    icon,
    activeIcon,
    itemKey,
    disabled = false,
    onBoxClick,
    display = "horizontal",
  } = props;

  return (
    <Box
      className={`content ${active ? "active" : ""} ${display} ${
        disabled ? "disabled" : ""
      }`}
      onClick={onBoxClick && onBoxClick.bind(null, itemKey)}
    >
      {icon && activeIcon && (
        <img src={active ? activeIcon : icon} alt={title} title={title} />
      )}
      <Box className="info">
        <Typography className="title" variant="subtitle2">
          {title}
        </Typography>
        {subTitle && (
          <Typography
            className="sub-title"
            variant="body2"
            style={{ color: active ? "#ffffff" : "var(--grey-text)" }}
          >
            {subTitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SelectionBox;
