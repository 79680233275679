import React, { useState } from "react";
import { DashboardTableCardProps } from "../../../../types/dashboard-table-card-props";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import "./dashboard-table-card.component.scoped.scss";
import { useTranslation } from "react-i18next";
import { TableColumn } from "../../../../types/table-column";
import { formatDate } from "../../../../helpers";
import { history } from "react-router-guard";
import { CaseTabs } from "../../../../statics/caseTabs";
import { AIRolesByKey } from "../../../case/types/AI-roles";
import { Box, Typography } from "@material-ui/core";
import { icons } from "../../../../statics/icons";

export default function DashboardTableCard(props: DashboardTableCardProps) {
  const [t] = useTranslation();
  const { title, subTitle, columns, data } = props;
  const [userImageError, setUserImageError] = useState(false);
  const handleRowClick = (caseId: number, tab: number) => {
    history.push(`/case/${caseId}`, {
      tab,
    });
  };

  return (
    <Box className="dashboard-card  global__box__content">
      <Box className="card-details">
        <Typography variant="subtitle1" className="card-title">
          {title}
        </Typography>
        <Typography variant="body1" className="card-sub-title">
          {subTitle}
        </Typography>
      </Box>
      {data.length > 0 ? (
        <table>
          <tr>
            {columns.map((column: TableColumn, index: number) => {
              return (
                <th
                  className="trim"
                  key={index}
                  style={{ width: column.width }}
                >
                  {t(column.fieldName)}
                </th>
              );
            })}
          </tr>

          {data.map((row: any, rowIndex: number) => {
            return (
              <tr
                style={{ cursor: "pointer" }}
                key={rowIndex}
                onClick={handleRowClick.bind(
                  null,
                  row.caseId,
                  CaseTabs.Description
                )}
              >
                {columns.map((column: TableColumn, columnIndex: number) => {
                  const { type, value, secondaryValue }: any = column;
                  return (
                    <td
                      className={type === "string" ? " trim" : ""}
                      key={columnIndex}
                      style={{ width: column.width }}
                    >
                      {type === "case_role" ? (
                        <Typography variant="body1">
                          {AIRolesByKey[row[value]].name}
                        </Typography>
                      ) : column.type === "object" ? (
                        <Box className="object">
                          <img
                            src={
                              row[column.value].iconKey
                                ? icons.PAGES.SETTINGS.ICON_OPTIONS[
                                    row[column.value].iconKey
                                  ]
                                : icons.categoryPrimary
                            }
                            alt=""
                          />
                          <Typography variant="body1" className="name">
                            {row[column.value].name}
                          </Typography>
                        </Box>
                      ) : type === "string" ? (
                        <Typography variant="body1">{row[value]}</Typography>
                      ) : type === "status" ? (
                        <Typography variant="body1">
                          {row[value] === 0 ? t("pending") : t("completed")}
                        </Typography>
                      ) : type === "user" ? (
                        <Box className="user__profile__pic">
                          {row[value].profilePicURL && !userImageError ? (
                            <img
                              src={row[value].profilePicURL}
                              alt=""
                              onError={setUserImageError.bind(null, true)}
                            />
                          ) : (
                            <span className="text-uppercase">
                              {row[value].firstName[0] + row[value].lastName[0]}
                            </span>
                          )}
                        </Box>
                      ) : type === "progress" ? (
                        <Box className="progress__bar">
                          <span
                            className={
                              row[value] === 100 ? "completed" : "progress"
                            }
                            style={{ width: row[value] + "%" }}
                          ></span>
                        </Box>
                      ) : type === "date" ? (
                        <Typography variant="body1">
                          {formatDate(row[value], "DD.MM.YYYY")}
                        </Typography>
                      ) : type === "release" ? (
                        <span>
                          {row.major.toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                          })}
                          .
                          {row.minor.toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                          })}
                          .
                          {row.version.toLocaleString("en-US", {
                            minimumIntegerDigits: 2,
                          })}
                        </span>
                      ) : type === "client" ? (
                        <Box className="client-cell">
                          <Box className="user__profile__pic">
                            <img src={row["img"]} alt="" />
                          </Box>
                          <Box className="client-info">
                            <Box className="client-name trim">{row[value]}</Box>
                            <Box className="branch-name trim">
                              {row[secondaryValue]}
                            </Box>
                          </Box>
                        </Box>
                      ) : type === "case" ? (
                        <>
                          <Typography variant="h6" className="case-number trim">
                            {row[value]}
                          </Typography>
                          <span className="case-admin trim">
                            {row[secondaryValue]}
                          </span>
                        </>
                      ) : (
                        <span className={type}>{row[value]}</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </table>
      ) : (
        <Box className="falback-wrapper">
          <img
            src="dashboard-fallback.png"
            className="empty-chart-fallback"
            alt="empty-charts"
          />
        </Box>
      )}
    </Box>
  );
}
