import React from "react";
import "./reset-password.page.scoped.scss";
import { useTranslation } from "react-i18next";
import { CommonService } from "../../services/common/common.service";
import ResetPasswordContentCompenent from "../../modules/auth/components/reset-password-content/reset-password-content.component";
import { AuthLayout } from "../../layouts/auth/auth.layout";

const commonService: CommonService = new CommonService();

const ResetPasswordPage = (props: any) => {
  const [t] = useTranslation();
  commonService.setTitle(t("Case Management"));

  const token = props.match.params.id;

  return (
    <AuthLayout>
      <ResetPasswordContentCompenent token={token} />
    </AuthLayout>
  );
};

export default ResetPasswordPage;
