import React from "react";
import "./dashboard-fifth-block.component.scoped.scss";
import { Box, Typography } from "@material-ui/core";
import { icons } from "../../../../statics/icons";
import { useTranslation } from "react-i18next";

const DashboardFifthBlockComponent = () => {
  const [t] = useTranslation();
  return (
    <Box className="content">
      <Box className="global__box__content p-0">
        <Box className="box__content">
          <Box className="info">
            <Typography variant="h6">
              {t("DASHBOARD_CARDS.QUESTIONS")}
            </Typography>
            <Typography variant="subtitle2">
              {t("DASHBOARD_CARDS.QUESTIONS_TEXT")}
            </Typography>
          </Box>
          <img
            src={icons.PAGES.DASHBOARD.COMMUNITY}
            alt=""
            className="m-auto"
          />
        </Box>
        <Box className="action">
          <a href={"https://ai-community.paiper.one"} target="_blank">
            {t("DASHBOARD_CARDS.GO_TO_HELP_CENTER")}
          </a>
        </Box>
      </Box>
      <Box className="global__box__content p-0">
        <Box className="box__content">
          <Box className="info">
            <Typography variant="h6">
              {t("DASHBOARD_CARDS.CONTACT_SUPPORT")}
            </Typography>
            <Typography variant="subtitle2">
              {t("DASHBOARD_CARDS.CONTACT_SUPPORT_TEXT")}
            </Typography>
          </Box>
          <img src={icons.PAGES.DASHBOARD.SUPPORT} alt="" className="m-auto" />
        </Box>
        <Box className="action">
          <a href={"https://paiper.one/ai-risk-assesment/"} target="_blank">
            {t("DASHBOARD_CARDS.CONTACT_SUPPORT")}
          </a>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardFifthBlockComponent;
