import { TableColumn } from "../../types/table-column";

export const openTasksColumns: TableColumn[] = [
  {
    type: "case",
    fieldName: "TASKS",
    value: "question",
    secondaryValue: "task",
    width: "50%",
  },
  {
    type: "status",
    fieldName: "STATUS",
    value: "status",
    width: "10%",
  },
  {
    type: "date",
    fieldName: "DUE_DATE",
    value: "deadline",
    width: "10%",
  },
  {
    type: "user",
    fieldName: "OWNER",
    value: "responsible",
    width: "10%",
  },
];
