import React, { useContext, useState } from "react";
import "./team-tab-content.component.scoped.scss";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CaseContext } from "../../../../../store";
import { TableColumn } from "../../../../../types/table-column";
import { formatDate } from "../../../../../helpers";
import { AddUserDialog } from "../../../../../dialogs/add-user/add-user";
import { Skeleton } from "@material-ui/lab";
import { SorterComponent } from "../../../../../components/table/sorter/sorter.component";
import { teamColumns } from "../../../../../statics/table-columns/team-columns";

const TeamTabContentComponent = (props: any) => {
  const { sortUsers } = props;
  const [t] = useTranslation();
  const { caseUsers, usersLoading, caseDetails } = useContext(CaseContext);
  const [userImageError, setUserImageError] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [tableOption, setTableOption] = useState({
    sortDirection: "asc",
    sortColumn: "",
  });

  const sort = (sortColumn: string, sortDirection: string) => {
    const sortOption = {
      order: sortDirection,
      sort: sortColumn,
    };
    setTableOption({
      sortColumn,
      sortDirection,
    });
    sortUsers(caseDetails.id, sortOption);
  };

  return (
    <Box className="table-section">
      <Box className="tab-head mb-4 d-flex justify-content-between align-items-center">
        <Box className="head">
          <Typography variant="h5" className="title">
            {t("ASSIGNED_USERS")}
          </Typography>

          <Typography variant="body2" className="sub-title">
            {`${caseUsers.length} ${t("user")} 
            (${
              caseUsers.filter((item: any) => item.role === "editor").length
            } ${t("editor")} / ${
              caseUsers.filter((item: any) => item.role === "auditor").length
            } ${t("auditor")}
            )`}
          </Typography>
        </Box>

        {usersLoading ? (
          <Skeleton variant="rect" height={35} width={220} />
        ) : (
          <Button
            className="btn-primary-theme"
            variant="contained"
            size="small"
            color="primary"
            onClick={setShowUserModal.bind(null, true)}
          >
            {t("USERS_MANAGEMENT")}
          </Button>
        )}
      </Box>

      <Box className="tab-table">
        {/* Table Columns */}
        <Box className="tab-table-header">
          <Box className="d-flex align-items-center justify-content-between">
            {teamColumns.map((column: TableColumn, index: number) => {
              return (
                <Box
                  className="tab-table-column"
                  key={index}
                  style={{ width: column.width }}
                >
                  {t(column.fieldName)}

                  {column.type !== "keyContact" && (
                    <SorterComponent
                      isSorting={column.fieldName === tableOption.sortColumn}
                      direction={tableOption.sortDirection}
                      sortColumn={tableOption.sortColumn}
                      fieldName={column.fieldName}
                      sort={sort}
                    />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
        {/* Table Rows */}

        <Box className="tab-table-body">
          {usersLoading && caseUsers && !caseUsers.length && (
            <Box className="loader-area">
              <CircularProgress />
            </Box>
          )}
          {caseUsers && caseUsers.length === 0 && !usersLoading && (
            <Box className="no-result">{t("no data available")}</Box>
          )}

          {caseUsers.map((row: any, rowIndex: number) => {
            return (
              <Box
                className="tab-table-row d-flex justify-content-between"
                key={rowIndex}
              >
                {teamColumns.map((column: TableColumn, columnIndex: number) => {
                  const { type, value, secondaryValue }: any = column;
                  return (
                    <Box
                      className={
                        "tab-table-cell " + (type === "string" ? " trim" : "")
                      }
                      key={columnIndex}
                      style={{ width: column.width }}
                    >
                      {type === "string" ? (
                        row[value]
                      ) : type === "date" ? (
                        formatDate(row[value], "DD.MM.YYYY")
                      ) : type === "client" ? (
                        <Box
                          className="d-flex align-items-center"
                          style={{ gap: ".5rem" }}
                        >
                          <Box className="user__profile__pic">
                            {row.profilePicURL && !userImageError ? (
                              <img
                                src={row.profilePicURL}
                                alt=""
                                onError={setUserImageError.bind(null, true)}
                              />
                            ) : (
                              <span className="text-uppercase">
                                {row.firstName[0] + row.lastName[0]}
                              </span>
                            )}
                          </Box>
                          <Box>
                            <Box className="user-cell text-capitalize">
                              {row.firstName + " " + row.lastName}
                            </Box>
                            <Box className="branch-name">
                              {row.type === "superUser"
                                ? t("coordinator")
                                : (row.role === "auditor"
                                    ? t("auditor")
                                    : t("editor")) +
                                  "/" +
                                  t(row[secondaryValue])}
                            </Box>
                          </Box>
                        </Box>
                      ) : type === "keyContact" ? (
                        <Box>
                          {+row.id === +caseDetails.keyContactUserId
                            ? t("YES")
                            : t("NO")}
                        </Box>
                      ) : type === "email" ? (
                        <Box className="user-cell">{row[value]}</Box>
                      ) : (
                        <Box className={type}>{row[value]}</Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            );
          })}
        </Box>
      </Box>
      <AddUserDialog
        show={showUserModal}
        onClose={() => setShowUserModal(false)}
        isEditable={true}
      />
    </Box>
  );
};

export default TeamTabContentComponent;
