import { icons } from "../../../statics/icons";
import { ReactElement } from "react";

export interface CaseTabItem {
  tab: number;
  name: string;
  icon?: string;
  activeIcon: string;
  roles: string[];
}

export const caseTabItems: CaseTabItem[] = [
  {
    tab: 1,
    name: "USECASE_DETAILS.AI_PRODUCT",
    icon: icons.checkLight,
    activeIcon: icons.checkPrimary,
    roles: ["DEVELOPER", "DISTRIBUTER", "IMPORTER", "USER"],
  },
  {
    tab: 2,
    name: "USECASE_DETAILS.APPLICATION_AREAS",
    icon: icons.checkLight,
    activeIcon: icons.checkPrimary,
    roles: ["DEVELOPER", "DISTRIBUTER", "IMPORTER", "USER"],
  },
  {
    tab: 3,
    name: "USECASE_DETAILS.CLASSIFICATION",
    icon: icons.checkLight,
    activeIcon: icons.checkPrimary,
    roles: ["DEVELOPER", "DISTRIBUTER", "IMPORTER", "USER"],
  },

  {
    tab: 4,
    name: "USECASE_DETAILS.AI_CHECK",
    icon: icons.checkLight,
    activeIcon: icons.checkPrimary,
    roles: ["DEVELOPER", "DISTRIBUTER", "IMPORTER"],
  },
  {
    tab: 5,
    name: "USECASE_DETAILS.AI_TEAM",
    icon: icons.checkLight,
    activeIcon: icons.checkPrimary,
    roles: ["DEVELOPER", "DISTRIBUTER", "IMPORTER"],
  },
  {
    tab: 6,
    name: "USECASE_DETAILS.TASKS",
    icon: icons.checkLight,
    activeIcon: icons.checkPrimary,
    roles: ["DEVELOPER", "DISTRIBUTER", "IMPORTER"],
  },
  // {
  //   tab: 7,
  //   name: "USECASE_DETAILS.IMPACT",
  //   icon: icons.checkLight,
  //   activeIcon: icons.checkPrimary,
  // },
];

export interface CaseTabContent {
  tab: number;
  component: ReactElement;
}
