import React from "react";
import "./dashboard-fourth-block.component.scoped.scss";
import { useTranslation } from "react-i18next";
import DashboardTableCard from "../dashboard-table-card/dashboard-table-card.component";
import { Box } from "@material-ui/core";

export default function DashboardFourthBlock(props: any) {
  const { openTasks } = props;
  const [t] = useTranslation();

  return (
    <Box className="cards__content mb-5">
      {openTasks.data && (
        <Box className="card__content">
          <DashboardTableCard
            title={t(openTasks.title)}
            subTitle={t(openTasks.subTitle)}
            columns={openTasks.columns}
            tableName={openTasks.tableName}
            data={openTasks.data}
          />
        </Box>
      )}
    </Box>
  );
}
