import React, { useContext, useEffect } from "react";
import { CommonService } from "../../services/common/common.service";
import HeaderComponent from "../../components/header/header.component";
import { GlobalContext, UsersProvider } from "../../store";
import UsersContent from "../../modules/users/components/users-content/users-content.component";
import { useTranslation } from "react-i18next";

export default function UsersPage() {
  const [t] = useTranslation();
  const commonService = new CommonService();
  const { setPageTitle } = useContext(GlobalContext);

  useEffect(() => {
    setTimeout(() => {
      commonService.setTitle(t("PAGE_META_TITLES.USERS_MANAGEMENT"));
      setPageTitle(t("PAGE_META_TITLES.USERS_MANAGEMENT"));
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UsersProvider>
      <HeaderComponent />
      <UsersContent />
    </UsersProvider>
  );
}
